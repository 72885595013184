@font-face {
    font-family: 'JetBrainsMono';
    font-weight: 400;
    src: url(../assets/fonts/JetBrainsMono-Regular.woff2) format('woff2');
    font-display: swap;
}

@font-face {
    font-family: 'JetBrainsMono';
    font-weight: 600;
    src: url(../assets/fonts/JetBrainsMono-SemiBold.woff2) format("woff2");
    font-display: swap;
}